import moment from "moment";
import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

export default function CreateMatka() {
  const [payload, setPayload] = useState({
    closeTime: "",
    id: 0,
    isEnabled: true,
    maxStack: 0,
    minStack: 0,
    name: "",
    openTime: "",
    resultTime: "",
  });

  return (
    <div id="addBanner">
      <div class="page-heading">
        <h3>Create Matka</h3>
      </div>
      <div class="page-content">
        <div class="row" id="basic-table">
          <div class="col-12">
            <div class="card">
              <div class="card-content">
                <div class="card-body">
                  <div class="row"></div>
                  <div className="event_body series_value_set">
                    <Row>
                      <Col md={6}>
                        <Form>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicPassword"
                          >
                            <Form.Label>Create Matka</Form.Label>
                            <Form.Control
                              type="text"
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  name: e.target.value,
                                })
                              }
                              placeholder="Enter matka name"
                              name="min"
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Open Time</Form.Label>
                            <form>
                              <input
                                value={moment(payload.openTime).format(
                                  "YYYY-MM-DDTHH:mm"
                                )}
                                onChange={(e) =>
                                  setPayload({
                                    ...payload,
                                    openTime: moment(
                                      e.target.value
                                    ).toISOString(),
                                  })
                                }
                                type="datetime-local"
                              />
                            </form>
                          </Form.Group>

                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Close Time</Form.Label>
                            <form>
                              <input
                                value={moment(payload.closeTime).format(
                                  "YYYY-MM-DDTHH:mm"
                                )}
                                onChange={(e) =>
                                  setPayload({
                                    ...payload,
                                    closeTime: moment(
                                      e.target.value
                                    ).toISOString(),
                                  })
                                }
                                type="datetime-local"
                              />
                            </form>
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Result Time</Form.Label>
                            <form>
                              <input
                                value={moment(payload.resultTime).format(
                                  "YYYY-MM-DDTHH:mm"
                                )}
                                onChange={(e) =>
                                  setPayload({
                                    ...payload,
                                    resultTime: moment(
                                      e.target.value
                                    ).toISOString(),
                                  })
                                }
                                type="datetime-local"
                              />
                            </form>
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Is Active</Form.Label>
                            <Form.Switch type="checkbox" label="Active" />
                          </Form.Group>

                          <Button variant="primary">Save</Button>
                        </Form>
                      </Col>
                      <Col md={6}></Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
