import React, { useEffect, useState } from "react";
import { getAllMatkaAPI } from "../../service/matka.service";
import { Row } from "react-bootstrap";

export default function MatkaList() {
  const [data, setData] = useState([]);
  const getAllMatka = async () => {
    const { response } = await getAllMatkaAPI();
    if (response) {
      setData(response);
    }
  };
  useEffect(() => {
    getAllMatka();
  }, []);
  return (
    <div>
      <Row
        style={{
          display: "flex",
          flexDirection: "row",
          margin: "10px",
        }}
      >
        <h2 style={{ width: "auto", marginRight: "auto" }}>Matka List</h2>
        <button
          onClick={() => {
            window.location.href = "/dashboard/matka/create";
          }}
          style={{ width: "auto" }}
          className="btn btn-primary"
        >
          Create Matka
        </button>
      </Row>

      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Matka Name</th>
            <th>Open Time</th>
            <th>Close Time</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id}>
              <td>{item.matkaName}</td>
              <td>{item.openTime}</td>
              <td>{item.closeTime}</td>
              <td>
                <button className="btn btn-primary">Edit</button>
                <button className="btn btn-danger">Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
